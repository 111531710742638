import React from "react";
import Chart from "../../Components/Chart";
import Modal from "../../Components/Modal";
import { useHistory } from "react-router";
import { clear } from "idb-keyval";

const Index = () => {
  let history = useHistory();
  return (
    <div className="container">
      <div className="columns has-text-right">
        <div className="column has-text-left">
          {" "}
          <button
            className="is-danger button is-light"
            onClick={() => {
              clear();
              history.go(0);
            }}
          >
            Reset Tracker
          </button>
        </div>
        <div className="column"></div>
        <div className="column">
          <Modal history={history} />
        </div>
      </div>
      <Chart />
    </div>
  );
};

export default Index;
