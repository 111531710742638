import React, { useState, useEffect } from "react";
import { articles } from "./articles";
import Beautify from "react-text-format";
import "./wiki.scss";

const Article = ({ article }) => {
  return (
    <div className="is-hovered mb-3">
      <article
        style={{ cursor: "pointer" }}
        className={`box accordion box-shadow has-background-white mb-1 is-light`}
      >
        <div className="columns is-vcentered is-multiline">
          <div className="column is-8 has-text-left">
            <p className="has-text-weight-bold is-hovered underline">
              {article.name}
            </p>
          </div>
          <div className="column is-4">
            <div className="tags">
              <p className="tag has-text-weight-bold">{article.category}</p>
            </div>
          </div>
          <div className="column is-12">
            <div className="tags">
             
              {article.tags.map((tag, index) => (
                <span
                  key={index}
                  className={`tag has-text-weight-medium is-link is-light`}
                >
                  #{tag}
                </span>
              ))}
            </div>
          </div>
      
        </div>
      </article>
      <div className="column box mt-0 is-12 has-text-left">
       <Beautify className="has-text-weight-medium">{article.description}</Beautify>
      
      </div>
    </div>
  );
};

const Index = (props) => {
  let [article, setArticle] = useState("");
  let id = props.match.params.id;

  useEffect(() => {
    if (id) {
      let article = articles.find((event) => event.id === parseInt(id));

      setArticle(article);
    }
  }, [id]);

  const renderArticle = (article) => {
    return (
      <div className="columns  mt-6 has-text-centered">
        <div className="column is-2"></div>

        <div className="column is-8">
          {article ? (
            <Article article={article} />
          ) : (
            <p className="notification is-danger is-light">
              No related article found.
            </p>
          )}
        </div>

        <div className="column is-2"></div>
      </div>
    );
  };
  return (
    <div className="has-background-light ">
      <section id="portfolio" className="hero is-fullheight">
        <div className="container is-fluid content-wrapper">
          {renderArticle(article)}
        </div>
      </section>
    </div>
  );
};

export default Index;
