import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Report from "./report/index";
import Wiki from "./wiki/index";
import Article from "./wiki/article";
import Tracker from "./tracker";
import Header from "../Components/header";

const App = () => {
  return (
    <div className="columns">
      <div className="column is-12">
        <Router basename="/">
          <Route component={Header} />
          <Switch>
            <Route exact path="/" component={Report} />
            <Route exact path="/wiki" component={Wiki} />
            <Route exact path="/wiki/:id" component={Article} />
            <Route exact path="/tracker" component={Tracker} />
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default App;
