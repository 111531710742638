import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { articles } from "./articles";
import Fuse from "fuse.js";
import Beautify from "react-text-format";
import "./wiki.scss";

const fuse = new Fuse(articles, {
  threshold: 0.2,
  keys: ["name", "description", "tags", "category"],
});

const Article = ({ article }) => {
  let [state, setState] = useState(false);

  return (
    <div className="is-hovered mb-3">
      <article
        style={{ cursor: "pointer" }}
        className={`box accordion box-shadow has-background-white mb-1 is-light`}
        onClick={() => setState(!state)}
      >
        <div className="columns is-vcentered is-multiline">
          <div className="column is-8 has-text-left">
            <p className="has-text-weight-bold is-hovered underline">
              {article.name}
            </p>
          </div>
          <div className="column is-4">
            <div className="tags">
              <p className="tag has-text-weight-bold">{article.category}</p>
             
            </div>
          </div>
        </div>
      </article>
      {state && (
        <div className="column box mt-0 is-12 has-text-left">
          <Beautify className="has-text-weight-medium">{article.description}</Beautify>
        </div>
      )}
    </div>
  );
};

const Index = () => {
  const { register, handleSubmit, reset } = useForm();
  const [results, setResults] = useState();

  const onSend = ({ search }) => {
    setResults("");
    let _results = fuse.search(search);
    console.log("res", _results);
    if (_results && _results.length !== 0) {
      _results = _results.map((result) => result.item);
      setResults(_results);
    }
  };

  const renderArticles = (courses) => {
    let count = 0;
    let config = [];
    let store = [];
    config.push(store);
    courses.forEach((course) => {
      if (count > 2) {
        store = [];
        store.push(course);
        config.push(store);
        count = 1;
      } else {
        store.push(course);
        count++;
      }
    });

    return (
      <div className="columns  mt-6 has-text-centered">
        <div className="column is-2"></div>

        <div className="column is-8">
          {config.map((set, index) => {
            return (
              <div key={index}>
                {set.map((article, index) => (
                  <div key={index}>
                    <Article article={article} />
                  </div>
                ))}
              </div>
            );
          })}
        </div>

        <div className="column is-2"></div>
      </div>
    );
  };
  return (
    <div className="has-background-light ">
      <section id="portfolio" className="hero is-fullheight">
        <div className="container is-fluid content-wrapper">
          <div className="columns is-centered is-multiline">
            <div className="column">
              <div className="columns mt-5">
                <div className="column is-3"></div>
                <div className="column has-text-centered">
                  {" "}
                  <div className="columns">
                    <div className="column is-10">
                      <form onSubmit={handleSubmit(onSend)}>
                        <div className="field">
                          <input
                            className="input"
                            name="search"
                            type="text"
                            placeholder="Enter your search here"
                            ref={register}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="column is-2">
                      {" "}
                      <button
                        className="button is-primary"
                        onClick={() => {
                          setResults("");
                          reset();
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <div className="column  is-3"></div>
              </div>
            </div>
          </div>
          {results ? renderArticles(results) : renderArticles(articles)}
        </div>
      </section>
    </div>
  );
};

export default Index;
