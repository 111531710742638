import { useEffect, useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useForm } from "react-hook-form";
import { set, get } from "idb-keyval";

import "./index.scss";
export default function Index({ history }) {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const { register, handleSubmit } = useForm({
    defaultValues: { timestamp: new Date().toISOString() },
  });

  const question = (question, name, type = "number") => {
    return (
      <div className="field">
        <label className="label">{`${question}:`}</label>

        <div className="control">
          <input
            className="input"
            type={type}
            min={0}
            step={1}
            max={10}
            ref={register({ required: true })}
            name={name}
          />
          <p className="mt-1">
            Select your level of mode with 0 being extremely unhappy and 10
            being extremely happy
          </p>
        </div>
      </div>
    );
  };

  const question1 = (question, name, type = "number", message) => {
    return (
      <div className="field">
        <label className="label">{`${question}`}</label>
        <div className="control">
          <input
            className="input"
            type={type}
            min={0}
            step={1}
            max={100}
            ref={register({ required: true })}
            name={name}
          />
          <p className="mt-1">{message}</p>
        </div>
      </div>
    );
  };

  const date = (question, name, type = "number", message) => {
    return (
      <div className="field">
        <label className="label">{`${question}`}</label>
        <div className="control">
          <input
            className="input"
            type={type}
            min={0}
            step={1}
            max={20}
            defaultValue={new Date().toISOString()}
            ref={register({ required: true })}
            name={name}
          />
          <p className="mt-1">{message}</p>
        </div>
      </div>
    );
  };

  const onSubmit = async (data) => {
    let store = await get("store");

    let { medicines, symptoms, mood, timestamp } = data;

    if (!timestamp) {
      timestamp = new Date().toISOString();
    }

    let record = {
      timestamp,
      medicines: Number(medicines),
      symptoms: Number(symptoms),
      mood: Number(mood),
    };

    if (store && typeof Array.isArray(JSON.parse(store))) {
      store = JSON.parse(store);
      store.push(record);

      store = JSON.stringify(store);
      await set("store", store);
    } else {
      store = [record];
      store = JSON.stringify(store);
      await set("store", store);
    }

    handleClose();

    history.go(0);
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <span onClick={handleClickOpen("paper")}>
        <button className="button is-link mr-2">Track Data</button>
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        style={{ minWidth: 400 }}
      >
        <section className="mr-5 mt-4 mb-4">
          <div className="container has-text-centered">
            <div className="columns has-text-right">
              <div className="column"> </div>
              <div className="column"></div>
              <div className="column"></div>
            </div>
            <p className="title">Tracker</p>

            <form
              className="notification has-background-white has-text-left"
              onSubmit={handleSubmit(onSubmit)}
            >
              {question("Mood Level*", "mood")}
              {question1(
                "Medicines*",
                "medicines",
                "number",
                "Please record the number of medications that you took today."
              )}
              {question1(
                "Symptoms*",
                "symptoms",
                "number",
                "Please record the number of symptoms you're experiencing today."
              )}
              {date(
                "Date",
                "timestamp",
                "text",
                "By default current time is recorded (or) you can add any specific date and time"
              )}

              <div className="field has-text-centered">
                <br />{" "}
                <div className="level">
                  <div className="level-item">
                    <div className="buttons">
                      {" "}
                      <button className="button is-link" type="submit">
                        Submit
                      </button>
                      <input
                        className="button is-light"
                        type="button"
                        value="Cancel"
                        onClick={() => handleClose()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Dialog>
    </div>
  );
}
