import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  let pathname = props.location.pathname;

  return (
    <section
      style={{ marginTop: 35, marginBottom: 30, backgroundColor: "#F5F5F5" }}
    >
      <div className="container">
        <nav className="navbar is-transparent">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <p className="has-text-weight-semibold"> 🧠  Dr. Brain</p>
            </Link>

            <div className="navbar-burger" style={{ current: "pointer" }}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={`navbar-menu`}>
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <Link
                to="/"
                className={`navbar-item ${
                  pathname === "/" ? `has-text-link` : ``
                }`}
              >
                <p className="has-text-weight-semibold"> Questionnaire</p>
              </Link>
              <Link
                to="/wiki"
                className={`navbar-item ${
                  pathname === "/wiki" ? `has-text-link` : ``
                }`}
              >
                <p className="has-text-weight-semibold"> Wiki</p>
              </Link>
              <Link
                to="/tracker"
                className={`navbar-item ${
                  pathname === "/tracker" ? `has-text-link` : ``
                }`}
              >
                <p className="has-text-weight-semibold"> Tracker</p>
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Header;
