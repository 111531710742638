const questions = [
  { qs: 'Sometimes I hear my thoughts inside my head.', type: `p1` }, { qs: 'People tell me that my tone of voice does not reflect my real feelings.', type: `n1` },
  { qs: 'My train of thought is frequently interrupted.', type: `n2` }, { qs: 'I believe that others are capable of reading my thoughts.', type: `p2` }, { qs: 'Frequently, I do nothing.', type: `n3` },
  { qs: 'I feel that my sexual drive has decreased.', type: `n4` }, { qs: 'I often repeat the same sentences.', type: `p3` }, { qs: 'While doing things, I have trouble concentrating.', type: `n5` },
  { qs: 'I have good ideas, but I have difficulty focusing on them.', type: `p4` }, { qs: 'I hear voices inside my head.', type: `p1` }, { qs: 'People sometimes do not comprehend why I am so happy or sad.', type: `p5` },
  { qs: 'I often think about creatures from outer space that want to conquer the Planet Earth.', type: `p2` }, { qs: 'I usually do not outwardly express my feelings.', type: `n1` }, { qs: 'I often feel anger or shout without any trigger.', type: `p3` },
  { qs: 'When people ask me questions, I cannot find the strength to respond.', type: `n2` }, { qs: 'People tell me that my ideas are disorganized or confused.', type: `p4` }, { qs: 'Sometimes I just stare.', type: `n3` },
  { qs: 'When I see a happy movie, I sometimes look sad.', type: `p5` }, { qs: 'I have difficulty maintaining eye contact with others.', type: `n1` }, { qs: 'Sometimes I hear voices that say bad or good things about me.', type: `p1` },
  { qs: 'I tend to be inactive.', type: `n3` }, { qs: 'My sexual drive is less than in the past.', type: `n4` }, { qs: 'I receive secret messages from the TV or the radio.', type: `p2` },
  { qs: 'I have trouble concentrating.', type: `n5` }, { qs: 'My clothes are not clean.', type: `p3` }, { qs: 'Several times I have seen things that others cannot see.', type: `p1` },
  { qs: 'While talking, my speech is not organized and I often switch topics (from one subject to another).', type: `p4` }, { qs: 'My (mental) disorder has a specific philosophical meaning.', type: `p2` }, { qs: 'I frequently laugh without any reason.', type: `p5` },
  { qs: 'I have difficulty sitting still.', type: `p3` }, { qs: 'When others laugh from jokes on TV, I usually do not.', type: `n1` }, { qs: 'I have trouble thinking as logically as I once did.', type: `p4` },
  { qs: 'I cannot enjoy or be enthusiastic about things.', type: `n1` }, { qs: 'People say that my speech is vague, unclear.', type: `n2` }, { qs: 'I feel distant from all people.', type: `n4` },
  { qs: 'I have difficulties finishing my work.', type: `n3` }, { qs: 'I sometimes look too much at details and then lose track of my thoughts.', type: `n5` }, { qs: 'I prefer being alone.', type: `n4` },
  { qs: 'Sometimes I get orders from voices that are being broadcasted to me.', type: `p1` }, { qs: 'I have trouble concentrating while reading.', type: `n5` }, { qs: 'I have committed a sin and this is unforgivable.', type: `p2` },
  { qs: 'The medication I receive has special powers.', type: `p2` }, { qs: 'If it were up to me, I would rarely change my clothes.', type: `n3` }, { qs: 'Sometimes people easily annoy me.', type: `p3` },
  { qs: 'Sometimes I get into trouble due to my sexual behavior.', type: `p3` }, { qs: 'I often do not know what I am feeling.', type: `n1` }, { qs: 'People have difficulty understanding my speech.', type: `p4` },
  { qs: 'I often do not know what to answer when asked a question.', type: `n2` }, { qs: 'Sometimes I laugh because of the voices I hear.', type: `p5` }, { qs: 'People might say that I am not interested in many things.', type: `n3` },
  { qs: 'People say that I look "frozen".', type: `n1` }, { qs: 'People say that I am a loner.', type: `n4` }, { qs: 'When people ask me questions, I answer them only after considerable delay.', type: `n2` },
  { qs: 'When I listen to others, I sometimes lose track of my thoughts.', type: `n5` }, { qs: 'Others say that my body odor is bad.', type: `n3` }, { qs: 'Some individuals on the unit are in fact FBI workers.', type: `p2` },
  { qs: 'I do not have friends.', type: `n4` }, { qs: 'Sometimes I cannot finish the sentence I just started.', type: `n2` }, { qs: 'Others say that I am not attentive to my surroundings.', type: `n5` },
  { qs: 'Others say that I spread around bad smells.', type: `n3` }, { qs: 'Sometimes I hear strange voices.', type: `p1` }, { qs: 'I have difficulty starting a conversation with other people.', type: `n4` },
  { qs: 'People broadcast thoughts inside my head.', type: `p2` }, { qs: 'People from outer space control my thoughts.', type: `p2` }, { qs: 'Others say that my clothes are not tidy.', type: `p3` },
  { qs: 'If I were invited to a party, I would go to it.', type: `n4` }, { qs: 'I have difficulty explaining myself.', type: `p4` }, { qs: 'Sometimes others do not understand why I laugh.', type: `p5` }
]

const definitions = [{ name: "Hallucinations", id: 13, description: "Hallucinations are the experience of hearing, seeing, smelling, tasting, or feeling things that are not there. They are vivid and clear with an impression similar to normal perceptions. " },
{ name: "Delusions", id: 18, description: "Delusions are fixed false beliefs held despite clear or reasonable evidence that they are not true. " },
{ name: "Thought disorderness", id: 28, description: "Thought disorder is a disorganized way of thinking that leads to abnormal ways of expressing language when speaking and writing." },
{ name: "Bizarre/ Disorganized behavior", id: 33, description: "Disorganized behavior are movements that can range from childlike silliness to unpredictable agitation or can manifest as repeated movements without purpose. " },
{ name: "Inappropriate affect", id: 36, description: "Emotional responses that are not in keeping with the situation or are incompatible with expressed thoughts or wishes, such as smiling when told about the death of a friend." },
{ name: "Restricted affect", id: 47, description: "Emotional expression that is reduced in range and intensity. It is common in depression, inhibited personalities, and schizophrenia." }, { name: "Alogia/ Reduced speech", description: "Negative symptoms include decreased thought and speech productivity." },
{ name: "Avolition/ Apathy",id:54, description: "Decreased initiation of goal-directed behavior." },
{ name: "Associality/ Anhedonia", id: 59, description: "Loss of ability to experience pleasure." },
{ name: "Reduced attention", id: 62, description: "Reduced attention, in psychology, refers to the reduced concentration of awareness on some phenomenon to the exclusion of other stimuli." }]


export { questions, definitions };