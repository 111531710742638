import { PureComponent } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { get } from "idb-keyval";
am4core.useTheme(am4themes_animated);

// Data for both series
//

am4core.options.onlyShowOnViewport = true;
am4core.options.queue = true;
am4core.options.minPolylineStep = 2;

class Index extends PureComponent {
  async componentDidMount() {
    let chart = am4core.create("tracker", am4charts.XYChart);

    // Export
    chart.exporting.menu = new am4core.ExportMenu();
    chart.legend = new am4charts.Legend();
    chart.exporting.menu.align = "right";

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.labels.template.fill = am4core.color("#000");
    dateAxis.title.text = "Time";
    dateAxis.title.fill = am4core.color("#000");
    dateAxis.groupData = true;
    dateAxis.renderer.grid.template.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.title.text = "Count";
    valueAxis.min = 0;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.renderer.opposite = true;
    valueAxis2.title.rotation = -90;
    valueAxis2.title.text = "Mood Level";
    valueAxis2.min = 0;
    valueAxis2.max = 10;

    const genColumnSeries = (name, value, date) => {
      /* Create series */
      let columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.yAxis = valueAxis;
      columnSeries.name = name;
      columnSeries.dataFields.valueY = value;
      columnSeries.dataFields.dateX = date;
      columnSeries.columns.template.tooltipText =
        "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";
      columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
      columnSeries.columns.template.propertyFields.stroke = "stroke";
      columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
      columnSeries.columns.template.propertyFields.strokeDasharray =
        "columnDash";
      columnSeries.tooltip.label.textAlign = "middle";
    };

    const genLineSeries = (name, value, date) => {
      let lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.name = name;
      lineSeries.yAxis = valueAxis2;
      lineSeries.dataFields.valueY = value;
      lineSeries.dataFields.dateX = date;
      lineSeries.stroke = am4core.color("#fdd400");
      lineSeries.strokeWidth = 3;
      lineSeries.propertyFields.strokeDasharray = "lineDash";
      lineSeries.tooltip.label.textAlign = "middle";

      let bullet = lineSeries.bullets.push(new am4charts.Bullet());
      bullet.fill = am4core.color("#fdd400"); // tooltips grab fill from parent by default
      bullet.tooltipText =
        "[#fff font-size: 15px]{name} Level {categoryX}:[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]";
      let circle = bullet.createChild(am4core.Circle);
      circle.radius = 4;
      circle.fill = am4core.color("#fff");
      circle.strokeWidth = 3;
    };

    genColumnSeries("Medicines", "medicines", "timestamp");
    genColumnSeries("Symptoms", "symptoms", "timestamp");
    genLineSeries("Mood", "mood", "timestamp");

    let data = await get("store");

    if (data) {
      data = JSON.parse(data);
    } else {
      data = [];
    }

    this.data = data;

    chart.cursor = new am4charts.XYCursor();

    chart.data = data;

    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  initiateReload = (data, stopReinit) => {
    this.chart.data = data;
    if (!stopReinit) {
      this.chart.reinit();
    }
  };

  render() {
    return (
      <>
        <div id={"tracker"} style={{ width: "100%", minHeight: "550px" }}></div>
      </>
    );
  }
}

export default Index;
