export const articles = [
  {
    id: 1, name: `What Is Schizophrenia?`, tags: ["schizophrenia", "symptoms"], category: `Overview`, description: `Schizophrenia is a chronic brain disorder that affects less than one percent of the U.S. population. When schizophrenia is active, symptoms can include delusions, hallucinations, disorganized speech, trouble with thinking and lack of motivation. However, with treatment, most symptoms of schizophrenia will greatly improve, and the likelihood of a recurrence can be diminished.

While there is no cure for schizophrenia, research is leading to innovative and safer treatments. Experts also are unraveling the causes of the disease by studying genetics, conducting behavioral research, and using advanced imaging to look at the brain’s structure and function. These approaches hold the promise of new, and more effective therapies.
The complexity of schizophrenia may help explain why there are misconceptions about the disease. Schizophrenia does not mean split personality or multiple personality. Most people with schizophrenia are not any more dangerous or violent than people in the general population. While limited mental health resources in the community may lead to homelessness and frequent hospitalizations, it is a misconception that people with schizophrenia end up homeless or living in hospitals. Most people with schizophrenia live with their family, in group homes or on their own.
Research has shown that schizophrenia affects men and women fairly equally but may have an earlier onset in males. Rates are similar around the world. People with schizophrenia are more likely to die younger than the general population, largely because of high rates of co-occurring medical conditions, such as heart disease and diabetes.`, link: `https://www.psychiatry.org/`
  },
  {
    id: 2, name: `Symptoms of Schizophrenia`, tags: ["symptoms", "positive symptoms", "negative symptoms", "Disorganized symptoms", "symptoms of schizophrenia", "Schizophrenic symptoms"], category: `Symptoms`, description: `1.	Positive symptoms - (those abnormally present) Hallucinations, such as hearing voices or seeing things that do not exist, paranoia and exaggerated or distorted perceptions, beliefs and behaviors.
2.	Negative symptoms - (those abnormally absent) A loss or a decrease in the ability to initiate plans, speak, express emotion or find pleasure
3.	Disorganized symptoms - Confused and disordered thinking and speech, trouble with logical thinking and sometimes bizarre behavior or abnormal movements.

Cognition is another area of functioning that is affected in schizophrenia leading to problems with attention, concentration and memory, and to declining educational performance.`, link: `https://www.psychiatry.org/`
  },
  {
    id: 3, name: `Causes of Schizophrenia`, tags: ["causes", "cause of schizophrenia", "Genetics", "Environment causes", "Brain chemistry", "Substance use"], category: `Causes`, description: `1.	Genetics - Schizophrenia isn’t caused by just one genetic variation, but a complex interplay of genetics and environmental influences. Heredity does play a strong role—your likelihood of developing schizophrenia is more than six times higher if you have a close relative, such as a parent or sibling, with the disorder.
2.	Environment - Exposure to viruses or malnutrition before birth, particularly in the first and second trimesters has been shown to increase the risk of schizophrenia. Recent research also suggests a relationship between autoimmune disorders and the development of psychosis. 
3.	Brain Chemistry - Problems with certain brain chemicals, including neurotransmitters called dopamine and glutamate, may contribute to schizophrenia. Neurotransmitters allow brain cells to communicate with each other. Networks of neurons are likely involved as well.
4.	Substance use - Some studies have suggested that taking mind-altering drugs during teen years and young adulthood can increase the risk of schizophrenia. A growing body of evidence indicates that smoking marijuana increases the risk of psychotic incidents and the risk of ongoing psychotic experiences. The younger and more frequent the use, the greater the risk.`, link: `https://www.nami.org/Home`
  }, {
    id: 4, name: `Medication for Schizophrenia`, tags: ["Medication", "Medication for Schizophrenia", "First generation antipsychotics", "Second generation antipsychotics", "Schizophrenic medication"], category: `Medication`, description: `1.	First Generation (Typical) Antipsychotics: These medications can cause serious movement problems that can be short (dystonia) or long term (called tardive dyskinesia), and also muscle stiffness. Other side effects can also occur.
Chlorpromazine (Thorazine)
Fluphenazine (Proxlixin)
Haloperidol (Haldol)
Loxapine (Loxitane)
Perphenazine (Trilafon)
Thiothixene (Navane)
Trifluoperazine (Stelazine)

2.	Second Generation (Atypical) Antipsychotics: These medications are called atypical because they are less likely to block dopamine and cause movement disorders. They do, however, increase the risk of weight gain and diabetes. Changes in nutrition and exercise, and possibly medication intervention, can help address these side effects.
Aripiprazole (Abilify)
Asenapine (Saphris)
Clozapine (Clozaril)
Iloperidone (Fanapt)
Lurasidone (Latuda)
Olanzapine (Zyprexa)
Paliperidone (Invega)
Risperidone (Risperdal)
Quetiapine (Seroquel)
Ziprasidone (Geodon)

One unique second generation antipsychotic medication is called clozapine. It is the only FDA approved antipsychotic medication for the treatment of refractory schizophrenia and has been the only one indicated to reduce thoughts of suicide. However, it does have multiple medical risks in addition to these benefits.`, link: `https://www.nami.org/Home`
  }, { id: 5, name: `Risk factors of Schizophrenia`, tags: ["Risks", "Risk", "Risk factors", "Risk factors of Schizophrenia"], category: `Risk factors`, description: `Researchers believe that a number of genetic and environmental factors contribute to causation, and life stressors may play a role in the start of symptoms and their course. Since multiple factors may contribute, scientists cannot yet be specific about the exact cause in each individual case.`, link: `https://www.psychiatry.org/` }, {
    id: 6, name: `Rehabilitation and living with schizophrenia`, tags: ["Rehabilitation", "Schizophrenia rehabilitation", "Living with Schizophrenia", "Schizophrenic living", "Rehabilitation and living"], category: `Rehabilitation/ living`, description: `Treatment can help many people with schizophrenia lead highly productive and rewarding lives. As with other chronic illnesses, some patients do extremely well while others continue to be symptomatic and need support and assistance.
After the symptoms of schizophrenia are controlled, various types of therapy can continue to help people manage the illness and improve their lives. Therapy and psychosocial supports can help people learn social skills, cope with stress, identify early warning signs of relapse and prolong periods of remission. Because schizophrenia typically strikes in early adulthood, individuals with the disorder often benefit from rehabilitation to help develop life-management skills, complete vocational or educational training, and hold a job. For example, supported-employment programs have been found to help people with schizophrenia obtain self-sufficiency. These programs provide people with severe mental illness competitive jobs in the community.
For many people living with schizophrenia family support is particularly important to their health and well-being. It is also essential for families to be informed and supported themselves. Organizations such as the Schizophrenia and Related Disorders Alliance of America (SARDAA), Mental Health America (MHA) and the National Alliance on Mental Illness (NAMI) offer resources and support to individuals with schizophrenia and other mental illnesses and their families.
Optimism is important and patients, family members and mental health professionals need to be mindful that many patients have a favorable course of illness, that challenges can often be addressed, and that patients have many personal strengths that must be recognized and supported.`, link: `https://www.psychiatry.org/`
  }, {
    id: 7, name: `Treatment for Schizophrenia`, tags: ["Treatment", "Treatment for Schizophrenia", "Schizophrenic treatment", "Antipsychotic medications", "Psychotherapy", "Psychosocial treatment", "Cognitive behavioral therapy", "Supportive psychotherapy", "Cognitive enhancement therapy", "Assertive Community treatment", "Peer support groups", "Self management", "Management"], category: `Treatment`, description: `1.	Antipsychotic medications.
2.	Psychotherapy:
a.	Cognitive behavioral therapy – It is an effective treatment for some people with affective disorders. With more serious conditions, including those with psychosis, additional cognitive therapy is added to basic CBT (CBTp). CBTp helps people develop coping strategies for persistent symptoms that do not respond to medicine.
b.	Supportive psychotherapy – It is used to help a person process his experience and to support him in coping while living with schizophrenia. It is not designed to uncover childhood experiences or activate traumatic experiences but is rather focused on the here and now.
c.	Cognitive Enhancement Therapy – It works to promote cognitive functioning and confidence in one’s cognitive ability. CET involves a combination of computer-based brain training and group sessions. This is an active area of research in the field at this time.
3.	Psychosocial treatment: 
a.	Assertive Community Treatment (ACT) - Assertive Community Treatment (ACT) provides comprehensive treatment for people with serious mental illnesses, such as schizophrenia. Unlike other community-based programs that connect people with mental health or other services, ACT provides highly individualized services directly to people with mental illness. Professionals work with people with schizophrenia and help them meet the challenges of daily life. ACT professionals also address problems proactively, prevent crises, and ensure medications are taken.
b.	Peer support groups like NAMI Peer-to-Peer encourage people’s involvement in their recovery by helping them work on social skills with others. 
c.	Complementary and alternative health approaches including acupuncture, meditation, and nutrition interventions can be part of a comprehensive treatment plan.
4.	Self-management strategies and education.`, link: `https://www.nami.org/Home`
  }, {
    id: 8, name: `Related conditions of Schizophrenia`, tags: ["Related conditions", "conditions", "Related conditions of Schizophrenia", "Schizophrenic conditions", "Delusional disorder", "Delusions", "Delusional beliefs", "Brief psychotic disorder", "Delusions", "Hallucinations", "Disorganized speech", "Grossly disorganized", "cationic behavior", "Schizophreniform disorder", "Negative symptoms", "Schizoaffective disorder", "PTSD", "Substance use disorder", "substance use", "OCD", "Depressive disorder"], category: `Related conditions`, description: `1.	Delusional Disorder - Delusional disorder involves a person having false beliefs (delusions) that persist for at least one month. The delusions can be bizarre (about things that cannot possibly occur) or non-bizarre (things that are possible but not likely, such as a belief about being followed or poisoned).

Apart from the delusion(s), it does not involve other symptoms. The person may not appear to have any problems with functioning and behavior except when they talk about or act on the delusion.

Delusional beliefs can lead to problems with relationships or at work, and to legal troubles. Delusional disorder is rare: around 0.2% of people will have it in their lifetime. Delusional disorder is treated with individual psychotherapy, although people rarely seek treatment as they often do not feel they need treatment.

2.	Brief Psychotic Disorder: Brief psychotic disorder occurs when a person experiences a sudden short period of psychotic behavior. This episode lasts between one day and one month and then the symptoms completely disappear, and the person returns to normal. Brief psychotic disorder involves one (or more) of the following symptoms:
1.	Delusions - Delusions are fixed false beliefs held despite clear or reasonable evidence that they are not true. Persecutory (or paranoid) delusions, when a person believes they are being harmed or harassed by another person or group, are the most common.
2.	Hallucinations - Hallucinations are the experience of hearing, seeing, smelling, tasting, or feeling things that are not there. They are vivid and clear with an impression similar to normal perceptions. Auditory hallucinations, “hearing voices,” are the most common in schizophrenia and related disorders.
3.	Disorganized speech - Disorganized thinking and speech refer to thoughts and speech that are jumbled and/or do not make sense. For example, the person may switch from one topic to another or respond with an unrelated topic in conversation. The symptoms are severe enough to cause substantial problems with normal communication.
4.	Grossly disorganized or catatonic behavior - Disorganized or abnormal motor behavior are movements that can range from childlike silliness to unpredictable agitation or can manifest as repeated movements without purpose. When the behavior is severe, it can cause problems in the performance of activities of daily life. It includes catatonia, when a person appears as if in a daze with little movement or response to the surrounding environment.

3.	Schizophreniform disorder: The symptoms of schizophreniform disorder are similar to those of schizophrenia, but the symptoms only last a short time—at least one month but less than six months. If the symptoms last longer than six months, then the diagnosis changes to schizophrenia.
Schizophreniform disorder involves two or more of the following symptoms, each present for a significant portion of time during a one-month period (or less if successfully treated):
1.	Delusions,
2.	Hallucinations
3.	Disorganized speech,
4.	Grossly disorganized behavior or catatonic behavior, and/or
5.	Negative symptoms - Negative symptoms refer to what is abnormally lacking or absent in the person with a psychotic disorder. Examples include impaired emotional expression, decreased speech output, reduced desire to have social contact or to engage in daily activities, and decreased experience of pleasure.
4.	Schizoaffective Disorder: People with schizoaffective disorder experience symptoms a major mood episode of depression or bipolar disorder (major depression or mania) at the same time as symptoms of schizophrenia (delusions, hallucinations, disorganized speech, grossly disorganized behavior, or negative symptoms). Symptoms of a major mood episode must be present for the majority of the duration of the active illness and there must be a period of at least two weeks when delusions or hallucinations are present in the absence of a mood episode.

Schizoaffective disorder is about one-third as common as schizophrenia, affecting about 0.3% of people at some time in their lives. The typical age at onset of schizoaffective disorder is early adulthood, although it can begin anywhere from adolescence to late in life. A significant number of individuals initially diagnosed with another psychotic illness later receive the diagnosis schizoaffective disorder when the pattern of mood episodes becomes apparent.

5.	Other related conditions include:
Substance use disorders/ Dual Diagnosis
Posttraumatic stress disorder (PTSD)
Obsessive-compulsive disorder (OCD)
Major depressive disorder
Successfully treating schizophrenia almost always improves these related illnesses. And successful treatment of substance misuse, PTSD or OCD usually improves the symptoms of schizophrenia.`, link: `https://www.psychiatry.org/`
  }, {
    id: 9, name: `Helping yourself`, tags: ["Helping yourself", "Help", "Help for Schizophrenia", "Support"], category: `Help`, description: `a. Manage Stress
b. Try to get plenty of sleep
c. Avoid alcohol and drugs
d. Maintain connections`, link: `https://www.nami.org/Home`
  }, {
    id: 10, name: `Helping A Family Member or Friend`, tags: ["Help a family member", "Help schizophrenic patients", "Help patients"], category: `Help`, description: `a. Respond calmly
b. Pay attention to triggers
c. Help ensure medications are taken as prescribed
d. Understanding lack of awareness (anosognosia).
e. Help avoid drugs or alcohol.`, link: `https://www.nami.org/Home`
  }, {
    id: 11, name: `Additional resources and support`, tags: ["Additional resources", "Support", "Additional support", "Resources", "Additional help", "Help"], category: `Additional resources`, description: `1. Schizophrenia and Related Disorders Alliance of America:
a. Schizophrenia Alliance (Self-help group) https://sardaa.org/schizophrenia-alliance/what-is-schizophrenia-alliance/
b. Meet the scientist webinars https://sardaa.org/events/
c. Find a local support group https://sardaa.org/schizophrenia-alliance/sa-group-locations/

2. Mental Health America:
a. Schizophrenia https://www.mhanational.org/conditions/schizophrenia
b. Live your life well https://www.mhanational.org/live-your-life-well
c. 800-969-6642

3.National Alliance on Mental Illness:
a.	Discussion groups https://nami.force.com/namiuserportal/s/login/?ec=302&inst=1Q&startURL=%2Fnamiuserportal%2Fidp%2Flogin%3Fapp%3D0sp1Q000000blPw%26binding%3DHttpPost%26inresponseto%3D_a7bf423b-37d6-4201-a00f-53b017b0e178
b.	800-950-NAMI (6264) 

4.National Institute on Mental health:
a. What is Schizophrenia https://www.nimh.nih.gov/health/topics/schizophrenia/index.shtml
b. Clinical trials 
https://www.clinicaltrials.gov/ct2/results?term=schizophrenia+OR+dementia+praecox+OR+psychotic+disorders+OR+schizophrenia+disorders+OR+schizophreniform+disorder+OR+schizoaffective+disorder+OR+catalepsy+OR+schizoid+personality+disorder+OR+schizotypical+personality+disorder&recr=Open&rslt=&type=&cond=&intr=&outc=&lead=&spons=&id=&state1=&cntry1=&state2=&cntry2=&state3=&cntry3=&locn=&gndr=&fund=0&rcv_s=&rcv_e=&lup_s=&lup_e=

5.National Suicide Prevention Lifeline – 1-800-273-TALK (8255) or text Crisis Text Line (text HELLO to 741741)

6.Behavioral Health Treatment Services Locator for finding mental health treatment facilities and programs https://findtreatment.samhsa.gov/

7.SAMHSA’s Early Serious Mental Illness Treatment Locator provides information about treatment facilities that offer coordinated specialty care https://www.samhsa.gov/esmi-treatment-locator?page=7

8.NIMH Help for Mental Illness https://www.nimh.nih.gov/health/find-help/index.shtml`, link: `https://www.nimh.nih.gov/health/topics/schizophrenia/index.shtml#part_160244`
  }, { id: 12, name: `What are Hallucinations?`, tags: ["Hallucinations", "Feeling sensations", "Hearing voices", "seeing patterns"], category: `Hallucinations`, description: `Hallucinations involve sensing things such as visions, sounds, or smells that seem real but are not. These things are created by the mind.`, link: `https://medlineplus.gov/ency/article/003258.htm` }, {
    id: 13, name: `Common hallucinations`, tags: ["Hallucinations", "Common symptoms", "Common hallucinations", "Hallucination symptoms"], category: `Hallucinations`, description: `Common hallucinations include:
•	Feeling sensations in the body, such as a crawling feeling on the skin or the movement of internal organs.
•	Hearing sounds, such as music, footsteps, windows or doors banging.
•	Hearing voices when no one has spoken (the most common type of hallucination). These voices may be positive, negative, or neutral. They may command someone to do something that may cause harm to themselves or others.
•	Seeing patterns, lights, beings, or objects that are not there.
•	Smelling an odor.
Sometimes, hallucinations are normal. For example, hearing the voice of or briefly seeing a loved one who recently died can be a part of the grieving process.`, link: `https://medlineplus.gov/ency/article/003258.htm`
  }, {
    id: 14, name: `Causes of Hallucinations`, tags: ["causes", "Hallucination causes", "Causes of hallucinations"], category: `Hallucinations`, description: `There are many causes of hallucinations, including:
•	Being drunk or high, or coming down from such drugs like marijuana, LSD, cocaine (including crack), PCP, amphetamines, heroin, ketamine, and alcohol
•	Delirium or dementia (visual hallucinations are most common)

•	Epilepsy that involves a part of the brain called the temporal lobe (odor hallucinations are most common)
•	Fever, especially in children and the older people
•	Narcolepsy (disorder that causes a person to fall into periods of deep sleep)
•	Mental disorders, such as schizophrenia and psychotic depression

•	Sensory problem, such as blindness or deafness
•	Severe illness, including liver failure, kidney failure, HIV/AIDS, and brain cancer`, link: `https://medlineplus.gov/ency/article/003258.htm`
  }, {
    id: 15, name: `When to Contact a Medical Professional?`, tags: ["Help", "Contact professional", "Help through professional"], category: `Hallucinations`, description: `A person who begins to hallucinate and is detached from reality should get checked by a health care professional right away. Many medical and mental conditions that can cause hallucinations may quickly become emergencies. The person should not be left alone.
Call your health care provider, go to the emergency room, or call 911 or the local emergency number.
A person who smells odors that are not there should also be evaluated by a provider. These hallucinations may be caused by medical conditions such as epilepsy and Parkinson disease.`, link: `https://medlineplus.gov/ency/article/003258.htm`
  }, {
    id: 16, name: `What to Expect at Your Office Visit?`, tags: ["Talk with your consultant", "Professional"], category: `Hallucinations `, description: `Your provider will do a physical examination and take a medical history. They will also ask you questions about your hallucinations. For example, how long the hallucinations have been happening, when they occur, or whether you have been taking medicines or using alcohol or illegal drugs.
Your provider may take a blood sample for testing.
Treatment depends on the cause of your hallucinations.`, link: ` https://medlineplus.gov/ency/article/003258.htm `
  }, { id: 17, name: `Alternative Names`, tags: ["Alternative names for Hallucinations", "Hallucination alternative names"], category: `Hallucinations `, description: `Sensory hallucinations`, link: ` https://medlineplus.gov/ency/article/003258.htm ` }, {
    id: 18, name: `What is delusional disorder?`, tags: ["Delusion disorder", "Psychosis", "Delusions", "Delusion"], category: `Delusions`, description: `Delusional disorder, previously called paranoid disorder, is a type of serious mental illness — called a “psychosis”— in which a person cannot tell what is real from what is imagined. The main feature of this disorder is the presence of delusions, which are unshakable beliefs in something untrue. People with delusional disorder experience non-bizarre delusions, which involve situations that could occur in real life, such as being followed, poisoned, deceived, conspired against, or loved from a distance. These delusions usually involve the misinterpretation of perceptions or experiences. In reality, however, the situations are either not true at all or highly exaggerated.
People with delusional disorder often can continue to socialize and function quite normally, apart from the subject of their delusion, and generally do not behave in an obviously odd or bizarre manner. This is unlike people with other psychotic disorders, who also might have delusions as a symptom of their disorder. In some cases, however, people with delusional disorder might become so preoccupied with their delusions that their lives are disrupted.
Although delusions might be a symptom of more common disorders, such as schizophrenia, delusional disorder itself is rather rare. Delusional disorder most often occurs in middle to late life.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, { id: 19, name: `What are the types of delusional disorder?`, tags: ["Types of delusional disorder?", "Delusion type", "Delusion types", "Erotomaniac", "Grandiose", "Jealous", "Persecutory", "Somatic", "Mixed"], category: `Delusions`, description: `xxxxxx`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder` }, {
    id: 20, name: `What causes delusional disorder?`, tags: ["Causes of delusion", "Delusion causes", "Genetic causes", "Biological causes", "Environmental causes", "Psychological causes"], category: `Delusions`, description: `As with many other psychotic disorders, the exact cause of delusional disorder is not yet known. Researchers are, however, looking at the role of various genetic, biological, and environmental or psychological factors.
•	Genetic. The fact that delusional disorder is more common in people who have family members with delusional disorder or schizophrenia suggests there might be a genetic factor involved. It is believed that, as with other mental disorders, a tendency to develop delusional disorder might be passed on from parents to their children.
•	Biological. Researchers are studying how abnormalities of certain areas of the brain might be involved in the development of delusional disorders. An imbalance of certain chemicals in the brain, called neurotransmitters, also has been linked to the formation of delusional symptoms. Neurotransmitters are substances that help nerve cells in the brain send messages to each other. An imbalance in these chemicals can interfere with the transmission of messages, leading to symptoms.
•	Environmental/psychological. Evidence suggests that delusional disorder can be triggered by stress. Alcohol and drug abuse also might contribute to the condition. People who tend to be isolated, such as immigrants or those with poor sight and hearing, appear to be more vulnerable to developing delusional disorder.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, {
    id: 21, name: `What are the symptoms of delusional disorder?`, tags: ["Delusion symptoms", "Symptoms of Delusions", "Hallucinations"], category: `Delusions`, description: `The presence of non-bizarre delusions is the most obvious symptom of this disorder. Other symptoms that might appear include:
•	An irritable, angry, or low mood
•	Hallucinations (seeing, hearing, or feeling things that are not really there) that are related to the delusion (For example, a person who believes he or she has an odor problem may smell a bad odor.)`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, {
    id: 22, name: `How is delusional disorder diagnosed?`, tags: ["Delusional disorder", "diagnosois", "Delusion diagnosis", "Diagnosis of Delusions"], category: `Delusions`, description: `If symptoms are present, your doctor will perform a complete medical history and physical examination. Although there are no laboratory tests to specifically diagnose delusional disorder, the doctor might use various diagnostic tests — such as X-rays or blood tests — to rule out physical illness as the cause of your symptoms.
If the doctor finds no physical reason for the symptoms, he or she might refer the person to a psychiatrist or psychologist, health care professionals who are specially trained to diagnose and treat mental illnesses. Psychiatrists and psychologists use specially designed interview and assessment tools to evaluate a person for a psychotic disorder. The doctor or therapist bases his or her diagnosis on the person’s report of symptoms, and his or her observation of the person’s attitude and behavior.
The doctor or therapist then determines if the person’s symptoms point to a specific disorder as outlined in the Diagnostic and Statistical Manual of Mental Disorders (DSM-5), which is published by the American Psychiatric Association and is the standard reference book for recognized mental illnesses. According to the DSM-5, a diagnosis of delusional disorder is made if a person has non-bizarre delusions for at least one month and does not have the characteristic symptoms of other psychotic disorders, such as schizophrenia.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, {
    id: 23, name: `How is delusional disorder treated?`, tags: ["Treatment for delusions", "Delusion treatment", "Psychotherapy", "Psychosocial treatments"], category: `Delusions`, description: `Treatment for delusional disorder most often includes medication and psychotherapy (a type of counseling); however, delusional disorder is highly resistant to treatment with medication alone. People with severe symptoms or who are at risk of hurting themselves or others might need to be in the hospital until the condition is stabilized.
Psychotherapy is the primary treatment for delusional disorder. It provides a safe environment for patients to discuss their symptoms while encouraging healthier and more functional attitudes and behaviors.
Psychosocial treatments. Various psychosocial treatments can help with the behavioral and psychological problems associated with delusional disorder. Through therapy, patients also can learn to control their symptoms, identify early warning signs of relapse, and develop a relapse prevention plan. Psychosocial therapies include the following:
•	Individual psychotherapy can help the person recognize and correct the underlying thinking that has become distorted.
•	Cognitive-behavioral therapy (CBT) helps the person learn to recognize and change thought patterns and behaviors that lead to troublesome feelings.
•	Family therapy can help families deal more effectively with a loved one who has delusional disorder, enabling them to contribute to a better outcome for the person.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, {
    id: 24, name: `Medication for Delusions`, tags: ["Delusion medication", "Medication for Delusions"], category: `Delusions`, description: `The primary medications used to attempt to treat delusional disorder are called anti-psychotics. Medications include the following:
•	Conventional antipsychotics, also called neuroleptics, have been used to treat mental disorders since the mid-1950s. These medicines work by blocking dopamine receptors in the brain. Dopamine is a neurotransmitter believed to be involved in the development of delusions. Conventional antipsychotics include chlorpromazine (Thorazine®), fluphenazine (Prolixin®), haloperidol (Haldol®), thiothixene (Navane®), trifluoperazine (Stelazine®), perphenazine (Trilafon®) and thioridazine (Mellaril®).
•	Newer medications — called atypical antipsychotic drugs — appear to be more effective in treating the symptoms of delusional disorder. These medications work by blocking dopamine and serotonin receptors in the brain. Serotonin is another neurotransmitter believed to be involved in delusional disorder. These drugs include risperidone (Risperdal®), clozapine (Clozaril®), quetiapine (Seroquel®), ziprasidone (Geodon®) and olanzapine (Zyprexa®).
•	Other medications that might be used to treat delusional disorder include tranquilizers and antidepressants. Tranquilizers might be used if the person has a very high level of anxiety and/or problems sleeping. Antidepressants might be used to treat depression, which often occurs in people with delusional disorder.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, { id: 25, name: `Can delusional disorder be prevented?`, tags: ["Prevention of delusions", "Delusion prevention"], category: `Delusions`, description: `There is no known way to prevent delusional disorder. However, early diagnosis and treatment can help decrease the disruption to the person’s life, family, and friendships.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder` }, {
    id: 26, name: `What is the outlook for people with delusional disorder?`, tags: ["People with delusions", "People with delusional disorder"], category: `Delusions`, description: `The outlook for people with delusional disorder varies depending on the person, the type of delusional disorder, and the person’s life circumstances, including the availability of support and a willingness to stick with treatment.
Delusional disorder is typically a chronic (ongoing) condition, but when properly treated, many people with this disorder can find relief from their symptoms. Some people recover completely and others experience episodes of delusional beliefs with periods of remission (lack of symptoms).
Unfortunately, many people with this disorder do not seek help. It often is difficult for people with a mental disorder to recognize that they are not well. They also might be too embarrassed or afraid to seek treatment. Without treatment, delusional disorder can be a life-long illness.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder`
  }, { id: 27, name: `What are the complications of delusional disorder?`, tags: ["Complications of delusional disorder?", "Delusions complications", "Complications of Delusions"], category: `Delusions`, description: `People with delusional disorder might become depressed, often as the result of difficulties associated with the delusions. Acting on the delusions also can lead to violence or legal problems; for example, a person with an erotomanic delusion may stalk or harass the object of his or her delusion, which could lead to arrest. Further, people with this disorder can eventually become alienated from others, especially if their delusions interfere with or damage their relationships.`, link: ` https://my.clevelandclinic.org/health/diseases/9599-delusional-disorder` }, {
    id: 28, name: `What is Thought disorderness?`, tags: ["Thought disorderness", "Speech disorderness"], category: `Thought disorderness`, description: `A thought disorder is a mental health condition that affects a person’s beliefs, thoughts, or perceptions. Thought disorders alter the way a person puts together ordered sequences of ideas and can affect a person’s behavior by causing them to experience paranoia, delusions, hallucinations, or other symptoms.
Thought disorders are usually diagnosed when a person’s behavior or speaking indicates problematic, illogical, or incoherent patterns of thinking. Thinking normally involves three parts: thinking about something, stringing thoughts together on what you are thinking about, and, finally, the delivery or flow of a thought pattern. A thought disorder disrupts one or more aspects of the thought process.
A thought disorder is distinct from speech disorders, which occur as a result of difficulty with speech patterns and production, rather than an underlying problem with thought processes. Schizophrenia is a type of thought disorder, and delusions—false beliefs that a person persists in believing despite conflicting evidence—can also be caused by thought disorders`, link: `https://www.goodtherapy.org/blog/psychpedia/thought-disorder#:~:text=A%20thought%20disorder%20is%20a,%2C%20hallucinations%2C%20or%20other%20symptoms.`
  }, {
    id: 29, name: `Symptoms of Thought Disorders`, tags: ["Symptoms of Thought Disorders", "Thought Disorders symptoms"], category: ` Thought disorderness `, description: `When people are diagnosed with a thought disorder, they are often diagnosed with some other condition that causes or contributes to the disordered thinking. Common symptoms of thought disorders include:
•	Rapid, incoherent, or illogical speech
•	Frequent interruptions in a person’s train of thought
•	Belief that a person or entity has removed the person’s thoughts
•	Delusions and false beliefs
•	Extremely tangential speech patterns, during which a person rapidly discusses several apparently unrelated topics
•	Inability to follow a logical train of thought or to clearly tell a story or convey an idea`, link: `https://www.goodtherapy.org/blog/psychpedia/thought-disorder#:~:text=A%20thought%20disorder%20is%20a,%2C%20hallucinations%2C%20or%20other%20symptoms.`
  }, {
    id: 30, name: `How are thought disorders treated?`, tags: ["Treatment for thought disorders treated?", "Thought disorders treatment"], category: ` Thought disorderness `, description: `People with schizophrenia commonly demonstrate symptoms of thought disorders, but other mental health issues—such as bipolar, schizotypal personality, and psychotic episodes—can also produce symptoms of a thought disorder. Treatment often involves psychotherapy and in some cases may include antipsychotic medications or mood stabilizers.
Psychotherapy is beneficial to individuals experiencing symptoms of a thought disorder because it allows a therapist to address behavioral and emotional issues that may be linked to or exacerbate the thought disorder. This type of treatment helps improve the quality of life for the person experiencing a thought disorder and may be part of a collaborative treatment approach.
Sometimes thought disorders can put people who experience them in danger. A person might, for example, believe something is safe that is unsafe, engage in risky behavior, or act on false beliefs that put others in danger. When a thought disorder interferes with safety, people with the condition may require psychiatric hospitalization.`, link: `https://www.goodtherapy.org/blog/psychpedia/thought-disorder#:~:text=A%20thought%20disorder%20is%20a,%2C%20hallucinations%2C%20or%20other%20symptoms.`
  }, {
    id: 31, name: `References`, tags: ["References for thought disorderness"], category: ` Thought disorderness `, description: `1.	Cotton, A. (2012, August 12). Unlocking the brain; CU doctors study thought disorder. The Denver Post. Retrieved from http://www.denverpost.com/news/ci_21292953/unlocking-brain-cu-doctors-study-thought-disorder
2.	Thought disorders. (n.d.). Minnesota Mental Health Consumer/Survivor Network. Retrieved from http://www.mhcsn.org/education/thought-disorders`, link: `https://www.goodtherapy.org/blog/psychpedia/thought-disorder#:~:text=A%20thought%20disorder%20is%20a,%2C%20hallucinations%2C%20or%20other%20symptoms.`
  }, {
    id: 32, name: `Bizarre/Psychotic Behavior - Facts about Psychotic Thinking`, tags: ["Bizarre behavior", "Psychotic bizarre behavior"], category: `Bizarre behavior`, description: `•The main feature of psychotic thinking is “being out of touch with reality.”
•	Bipolar disorder involves periods of serious depression combined with periods of extreme euphoria and frenzied thinking/behavior, the latter of which can reflect a poor reality. A person with bipolar disorder can become psychotic.
•	Psychological illness that involve psychotic features often have an onset between the late teens and early 30s.`, link: ` https://dean-of-students.providence.edu/the-care-team/the-care-team-bizarrepsychotic-behavior/`
  }, {
    id: 33, name: `Symptoms of Bizarre/Psychotic Behavior`, tags: ["Symptoms of Bizarre/Psychotic Behavior", "Bizarre/Psychotic Behavior symptoms", "Symptoms of Bizarre Behavior", "Symptoms of Psychotic Behavior"], category: `Bizarre behavior`, description: `oSpeech that makes no sense
o	Extremely odd or eccentric behavior
o	Inappropriate or complete lack of emotion
o	Bizarre behavior that could indicate hallucinations
o	Strange beliefs that involve a serious misinterpretation of reality
o	Social withdrawal
o	Inability to connect with or track normal communication
o	Extreme or unwarranted suspicion`, link: ` https://dean-of-students.providence.edu/the-care-team/the-care-team-bizarrepsychotic-behavior/`
  }, {
    id: 34, name: `What You Can Do to deal with Bizzare behavior`, tags: ["Help with Bizzare behavior", "Help with Psychotic behavior", "Dealing with Bizzare behavior", "Dealing with Psychotic behavior"], category: `Bizarre behavior`, description: `•Consult with a professional at Personal Counseling 401.865.2343.
•	Speak to the student in a direct and concrete manner regarding your plan for getting him/her to a safe environment.
•	Accompany the student to Personal Counseling 401.865.2343 or call Safety and Security at 401.865.2222 if the student is highly impaired.
•	Recognize that psychotic states can involve extreme emotion or lack of emotion and intense fear to the point of paranoia.
•	Recognize that a student in this state may be dangerous to self or others.`, link: ` https://dean-of-students.providence.edu/the-care-team/the-care-team-bizarrepsychotic-behavior/`
  }, {
    id: 35, name: `What You Should Avoid Doing`, tags: ["Things to avoid when having bizzare behavior"], category: `Bizarre behavior`, description: `•Assuming the student will be able to care for him/herself.
•	Agitating the student with questions, pressure to take specific actions, etc.
•	Arguing with the students unrealistic or irrational thoughts.
•	Assuming the student understands you.
•	Allowing friends/roommates to care for that student without getting professional advice as to whether or not that plan is appropriate.
•	Getting locked into one way of dealing with the student, be prepared to adapt to the specific situation.
•	Assuming the family knows about the student’s condition.`, link: ` https://dean-of-students.providence.edu/the-care-team/the-care-team-bizarrepsychotic-behavior/`
  }, {
    id: 36, name: `What is Inappropriate affect?`, tags: ["Inappropriate affect", "intrapsychic ataxia"], category: `Inappropriate affect`, description: `As noted, inappropriate affect involves the display of reactions that do not match the situation that you are in or possibly even your internal state.
Emotions, actions, or overall demeanor that seem out of place in a situation all fall under the general umbrella term "inappropriate affect."
Inappropriate affect can be a symptom of different underlying physical or mental problems, but it can also be a disorder itself. Psychiatrists and other professionals have recognized inappropriate affect as a problem among patients for over 100 years, reporting events such as laughter or tears without any cause.
In studying what we now know as schizophrenia in 1909, Stransky referred to "intrapsychic ataxia" as a condition involving a disturbance between thoughts and affect. In 1950, Bleuler noted that the clinical prognosis of schizophrenic patients displaying inappropriate affect was much poorer than those who did not.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 37, name: `Causes of Inappropriate affect`, tags: ["Causes of Inappropriate affect", "Inappropriate affect causes"], category: `Inappropriate affect`, description: `There are a number of different potential causes of inappropriate affect, but they fall under the categories described below.
Brain Damage or Neurological Dysfunction:
Inappropriate affect can result from physical damage to the brain such as that happening due to a brain tumor, brain damage, dementia, brain injury, head trauma, etc.
When inappropriate affect results from a structural brain problem, areas that are commonly involved include the limbic system, anterior cingulate cortex, and prefrontal cortex.
Schizophrenia and Other Psychotic Disorders:
A second main cause of inappropriate affect is mental disorders, including depression, schizophrenia, bipolar disorder, schizoaffective disorder, and post-traumatic stress disorder (PTSD). Often, these individuals have not lost the capacity for emotional reactions, but appear to have lost the ability to have them occur in a normal and expected way. This is because their affect is a reaction to their hallucinations or delusions and their assumptions about the reasons for other people's actions.
Instead, they may have strange reactions that don't seem to make sense to the outside observer. In this way, they show emotions and behavior that do not seem to be consistent with the reality of the situation, but they are in fact consistent with their internal experience of the situation.
In schizophrenia, a type of inappropriate affect is sometimes called "flat affect" when it presents as a reduced expression of emotion.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 38, name: `Medications to Treat Mental Disorders`, tags: ["Medications to Treat Mental Disorders", "Mental Disorders treatment"], category: `Inappropriate affect `, description: `According to a study in the International Journal of Bipolar Disorders, emotional blunting can also be caused by certain medications used to treat schizophrenia and other psychotic disorders, including: 1
•	Selective serotonin reuptake inhibitors (SSRIs) like Lexapro (escitalopram), Prozac (fluoxetine), Zoloft (sertraline), and Paxil (paroxetine)2
•	Selective norepinephrine reuptake inhibitors (SNRIs) such as Cymbalta (duloxetine), Pristiq (desvenlafaxine), and Effexor XR (venlafaxine)
•	Tricyclic and tetracyclic antidepressants like Elavil (amitriptyline) and Remeron (desvenlafaxine)
•	Antipsychotics like Serentil (mesoridazine), Thorazine (chlorpromazine), and Truxal ( chlorprothixene)
•	Lithium like Duralith, Eskalith, Lithobid, Lithotabs, Lithane, Lithizine, and Lithonate
•	Antiepileptics (anticonvulsants) like Valproate (Depakote), Gabapentin (Neurontin), and Topiramate (Topamax)
If you think your medication is causing emotional blunting, do not stop treatment without first speaking with your doctor who can work with you to lower your dose or switch you to a different medication. Stopping abruptly may lead to a rebound of depression symptoms or trigger the opposite effect, including anxiety, agitation, and sleeplessness.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, { id: 39, name: `Psychological Factors`, tags: ["Psychological Factors involved with Inappropriate affect", "Inappropriate affect"], category: `Inappropriate affect`, description: `There can also be other causes of inappropriate affect that is not due to brain damage or a mental disorder. In most of these cases, the person is capable of responding in a normal way, but for one reason or another does not. For example, a person in therapy may laugh while describing a difficult situation so as to control their emotions in a self protective way.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22` }, {
    id: 40, name: `Diagnosis of Inappropriate affect`, tags: ["Diagnosis of Inappropriate affect", "Inappropriate affect"], category: `Inappropriate affect `, description: `If you are concerned that you are or someone you know is living with inappropriate affect, it is important to obtain a diagnosis from a licensed mental health professional. Your doctor can refer you to the necessary professionals, such as a neurologist, psychologist, or psychiatrist
The first step would generally be to rule out any physical or neurological condition causing the inappropriate affect. Your mental health professional might use a mental status examination, which is a structured way of observing and evaluating a person's psychological function from the perspective of attitude, behavior, cognition, judgment, mood, perception, and thought processes.
Often, inappropriate affect is a symptom of another disorder rather than a syndrome itself, so an assessment would be conducted for mental disorders that could be a potential cause as well. The severity of the problem would also be assessed, to determine where it falls in the range from mild to severe.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 41, name: `Conditions Involving Inappropriate Affect`, tags: ["Conditions Involving Inappropriate Affect", "Inappropriate Affect conditions"], category: `Inappropriate affect `, description: `Various conditions may be associated with inappropriate affect:
Mental Disorders
•	Major depressive disorder
•	Schizophrenia
•	Schizoid personality disorder
•	Post-traumatic stress disorder
•	Schizoaffective disorder
•	Psychopathy
•	Depersonalization
•	Borderline personality disorder
Neurological Conditions
•	Brain damage
•	Pseudobulbar affect
•	Autism
•	Dementia
•	Alzheimer's disease
•	Head trauma
•	Brain tumor
•	Multiple sclerosis`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 42, name: `Symptoms of Inappropriate affect`, tags: ["Symptoms of Inappropriate affect", "Inappropriate affect symptoms"], category: `Inappropriate affect `, description: `The specific symptoms of inappropriate affect will depend on the underlying cause and show up in terms of facial expressions, the tone of your voice, and the content of what you say. Below are some potential symptoms:
•	Uncontrollable crying even when not feeling sad
•	Recognizing that your reactions are inappropriate
•	Emotions that do not match the reality of a situation
•	Showing happiness during a tragedy
•	Becoming angry without any outward provocation
•	Flat affect or not showing expected emotional reactions (but not in the case of simply holding back emotions)
Clearly, the specific types of symptoms experienced will depend on the underlying cause of the inappropriate affect.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 43, name: `Types of Inappropriate Affect`, tags: ["Types of Inappropriate Affect", "Inappropriate Affect types", "Inappropriate Affect"], category: `Inappropriate affect `, description: `In addition to the broad symptoms of inappropriate affect, there are a number of specific categories within which symptoms can be grouped. Remember that inappropriate affect is simply that which does not match the situation or the internal emotional state of the person.
The various types are listed below.
Blunted Affect
Blunted affect is a common symptom in schizophrenia and related disorders, and refers to a lowered level of the intensity of emotional expression. A person with blunted affect will show little feeling even in emotional situations. As an example, a person with blunted affect may tell a story about being in a car crash but talk little about how it made them feel, speak in a monotone, and not have many facial expressions.
Restricted Affect
Restricted affect is a reduction in one's expressive range. So, the person describing the car accident may show some feeling and emotion, but it is less than would be expected based on the content of what is being said.
Flat Affect
Flat affect refers to displaying absolutely no feeling or emotion regardless of the circumstances. This is a step below blunted affect in that it is completely devoid of emotional expression.
Labile Affect
A person with labile affect shows rapid changes in their emotions that don't seem to relate to any outside situations or seem to be inappropriate for the situation. In other words, if you have labile affect, you would display rapid and repeating shifts in mood or affect. A person with labile affect appears as though they do not have control over their emotions.
This type of affect is seen often in neurological disorders such as pseudobulbar affect, in manic episodes during bipolar disorder, and in borderline personality disorder. Labile affect can also result from fatigue, stress/anxiety, and over-stimulation.
Shallow Affect
Shallow affect has a similar meaning to blunted affect, but it is often used to describe the emotional experience of persons with psychopathy. A person with shallow affect will feel little emotion about situations that would expect to elicit specific feelings.
This means that things that cause most people to experience negative emotions do not have the same impact for psychopaths. As an example, American serial killer Richard Ramirez, also known as the Night Stalker, when sentenced to death, responded with, "Big deal. Death always went with the territory. See you in Disneyland."`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 44, name: `Treatment for Inappropriate affect`, tags: ["Treatment for Inappropriate affect", "Inappropriate affect treatment", "Inappropriate affect"], category: `Inappropriate affect`, description: `The treatment for inappropriate affect depends on the underlying cause. Receiving proper treatment depends on an accurate diagnosis, which is why it is so important to visit a healthcare professional if you or someone you know is living with these types of symptoms.
Most types of inappropriate affect will be treated with some form of medication depending on the severity of the symptoms. Even if psychotherapy is used, it is usually as an add-on to medication, because therapy alone often will not help to remediate the problem.
Medications that may be used include antipsychotics (in the case of schizophrenia or psychosis), antidepressants or mood stabilizers (in the case of mood disorders or related issues), as well as a specific medication approved by the U.S. Food and Drug Administration for the treatment of pseudobulbar affect (a combination of dextromethorphan and quinidine).`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, {
    id: 45, name: `Helping Someone With Inappropriate Affect`, tags: ["Helping Someone With Inappropriate Affect", "Helping people With Inappropriate Affect", "Support Someone With Inappropriate Affect"], category: `Inappropriate affect `, description: `If you are a caregiver to a person experiencing inappropriate affect, it can be hard on you too. You may react negatively or defensively to the other person's behaviors, which can make the situation worse. You might also be confused by their behavior and not know how to react.
It's important as a caregiver that you take time to get help for yourself as well. Support groups or other avenues for connecting with other people going through the same issues may be helpful. You could also consider talk therapy for yourself to learn best how to manage the situation and your own needs and emotions.`, link: `https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=As%20noted%2C%20inappropriate%20affect%20involves,umbrella%20term%20%22inappropriate%20affect.%22`
  }, { id: 46, name: `What is Restricted Affect?`, tags: ["What is Restricted Affect?", "Restricted Affect?"], category: `Restricted affect`, description: `Restricted affect is a reduction in one's expressive range. So, the person describing the car accident may show some feeling and emotion, but it is less than would be expected based on the content of what is being said.`, link: ` https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=Restricted%20affect%20is%20a%20reduction,of%20what%20is%20being%20said.` }, {
    id: 47, name: `Treatment for Restricted Affect`, tags: ["Treatment for Restricted Affect", "Restricted Affect", "Restricted Affect treatment"], category: `Restricted affect`, description: `The treatment for restricted affect depends on the underlying cause. Receiving proper treatment depends on an accurate diagnosis, which is why it is so important to visit a healthcare professional if you or someone you know is living with these types of symptoms.
Most types of inappropriate affects including restricted affect will be treated with some form of medication depending on the severity of the symptoms. Even if psychotherapy is used, it is usually as an add-on to medication, because therapy alone often will not help to remediate the problem.
Medications that may be used include antipsychotics (in the case of schizophrenia or psychosis), antidepressants or mood stabilizers (in the case of mood disorders or related issues), as well as a specific medication approved by the U.S. Food and Drug Administration for the treatment of pseudobulbar affect (a combination of dextromethorphan and quinidine).`, link: ` https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=Restricted%20affect%20is%20a%20reduction,of%20what%20is%20being%20said.`
  }, {
    id: 48, name: `Helping Someone With Restricted Affect`, tags: ["Helping Someone With Restricted Affect", "Restricted Affect"], category: `Restricted affect`, description: `If you are a caregiver to a person experiencing restricted affect, it can be hard on you too. You may react negatively or defensively to the other person's behaviors, which can make the situation worse. You might also be confused by their behavior and not know how to react.
It's important as a caregiver that you take time to get help for yourself as well. Support groups or other avenues for connecting with other people going through the same issues may be helpful. You could also consider talk therapy for yourself to learn best how to manage the situation and your own needs and emotions.`, link: ` https://www.verywellmind.com/understanding-inappropriate-affect-4767992#:~:text=Restricted%20affect%20is%20a%20reduction,of%20what%20is%20being%20said.`
  }, {
    id: 49, name: `Alogia/ Reduced speech`, tags: ["Alogia", "Reduced speech"], category: `Alogia/ Reduced Speech `, description: `Some people are naturally quiet and don’t say much. But if you have a serious mental illness, brain injury, or dementia, talking might be hard. This lack of conversation is called alogia, or “poverty of speech.”
Alogia can affect your quality of life. If you can’t carry on a conversation, you may withdraw from friends and family. Or you may not be able to work. But with a doctor’s help, there are ways to manage this condition.`, link: ` https://www.webmd.com/mental-health/what-is-alogia#:~:text=Some%20people%20are%20naturally%20quiet,or%20%E2%80%9Cpoverty%20of%20speech.%E2%80%9D`
  }, {
    id: 50, name: `Symptoms of Alogia`, tags: ["Alogia symptoms", "Symptoms of Alogia"], category: `Alogia/ Reduced Speech `, description: `Alogia is often linked to schizophrenia. It’s considered a negative (and sometimes early) symptom of schizophrenia. It’s negative because it takes away your ability to do something.
If you have alogia, you might:
•	Pause for a long time between words
•	Give short or one-word responses
•	Answer only what someone asks you
•	Have a flat tone in your voice
•	Have dull facial expressions
•	Use limited sign language (if you can’t hear)
Negative symptoms often come before a more serious psychotic break. Quick treatment may help you manage the condition better. If your child or loved one has alogia, they might not know. You may need to help them talk to a doctor.`, link: ` https://www.webmd.com/mental-health/what-is-alogia#:~:text=Some%20people%20are%20naturally%20quiet,or%20%E2%80%9Cpoverty%20of%20speech.%E2%80%9D`
  }, {
    id: 51, name: `Who’s at Risk of Alogia?`, tags: ["People at Risk of Alogia", "Alogia"], category: `Alogia/ Reduced Speech `, description: `Anywhere from 15%-30% of people with schizophrenia have negative symptoms. They may show up 1-3 years before behaviors like delusions.
Experts don’t know how many of those people will get alogia. But it happens less often than some of the other symptoms.
You may get alogia if you have a serious mental illness like bipolar disorder or major depression. Neurological problems caused by a traumatic brain injury, dementia, or Alzheimer’s disease can also lead to alogia.`, link: ` https://www.webmd.com/mental-health/what-is-alogia#:~:text=Some%20people%20are%20naturally%20quiet,or%20%E2%80%9Cpoverty%20of%20speech.%E2%80%9D`
  }, {
    id: 52, name: `What Causes Alogia?`, tags: ["Causes of Alogia", "Alogia causes"], category: `Alogia/ Reduced Speech`, description: `Experts aren’t sure, but they think it has something to do with how your brain gets information from long-term memories. That’s where words and their meaning are stored. Parts of your brain may have problems “talking” to each together. But more research is needed to find out exactly what’s going on.
Your speech problems may be caused by other things, like psychosis or anxiety. That’s called secondary alogia. For example, you may not talk because you hear voices that tell you not to speak. Or you might not talk because you feel nervous or paranoid around other people. In general, social situations can make alogia worse. That’s because it can be hard to think when there’s a lot going on. You may talk less when you’re stressed and your brain gets overwhelmed.`, link: ` https://www.webmd.com/mental-health/what-is-alogia#:~:text=Some%20people%20are%20naturally%20quiet,or%20%E2%80%9Cpoverty%20of%20speech.%E2%80%9D`
  }, {
    id: 53, name: `Treatment for alogia`, tags: ["Treatment for alogia", "Alogia treatment"], category: `Alogia/ Reduced Speech `, description: `Researchers are working on medications specifically for alogia. Right now, the most effective treatments involve a mix of medication and psychosocial therapy. That may include:
Medication. Your doctor will likely give you a drug known as a second-generation antipsychotic. They might want you to try an antidepressant. Experts don’t know if these drugs treat alogia directly. But your speech may improve if primary symptoms, such as depression or delusions, go away.
Psychosocial treatment. Your alogia may get better if you get help in other parts of your life. Talk therapy or other types of therapy, social skills training, and family education might help.
Speech therapy. Dementia can cause problems in your brain that make it hard to find words. That’s called anomia. It can also make it hard to process full sentences. A speech-language pathologist can help.`, link: ` https://www.webmd.com/mental-health/what-is-alogia#:~:text=Some%20people%20are%20naturally%20quiet,or%20%E2%80%9Cpoverty%20of%20speech.%E2%80%9D`
  }, {
    id: 54, name: `What is Avolition?`, tags: ["Avolition", "Apathy"], category: `Avolition/Apathy`, description: `It's normal to drag your feet when you have an annoying chore to do. But if you have "avolition," your habit of putting off a task is on a whole different level.
Avolition is a total lack of motivation that makes it hard to get anything done. You can't start or finish even simple, everyday tasks. Getting off the couch to wash the dishes or drive to the supermarket can feel like climbing Mount Everest.
Avolition is often a symptom of schizophrenia, a mental disorder that affects how you think, feel, and act. It can also be a sign of severe depression or a side effect of certain medicines.
If you don't get treatment, avolition can affect every part of your life, from your relationships to your job. It's hard to take care of yourself or your home when you don't have the drive to do anything.
Medicine and therapy can help treat avolition when it's caused by schizophrenia. You may need to try a few different types before you find one that helps.`, link: `https://www.webmd.com/mental-health/what-is-avolition#:~:text=It's%20normal%20to%20drag%20your,finish%20even%20simple%2C%20everyday%20tasks.`
  }, {
    id: 55, name: `What Causes Avolition?`, tags: ["Causes of Avolition?", "Avolition causes"], category: ` Avolition/ Apathy`, description: `Besides being a symptom of schizophrenia, avolition is also a side effect of some medicines. Some of the antipsychotic drugs that you take to treat schizophrenia can also cause it.
Some scientists believe that the link between avolition and schizophrenia has to do with a brain chemical called dopamine. Dopamine is involved with your brain's reward system.
One theory is that a drop in dopamine causes avolition. When you have less dopamine, you have less motivation. You may not want to do anything if you don't think you'll get rewarded for it.
Avolition is also a symptom of severe depression and other conditions, such as:
•	Bipolar disorder
•	Persistent depressive disorder (dysthymia)
•	Premenstrual dysphoric disorder (PDD)
•	Posttraumatic stress disorder (PTSD)
•	Traumatic brain injury
•	Alzheimer's disease
People who don't get enough mental stimulation can also have avolition. For example, you could get it if you sit alone in bed all day, perhaps because of an illness. It could also happen to prisoners in solitary confinement.`, link: ` https://www.webmd.com/mental-health/what-is-avolition#:~:text=It's%20normal%20to%20drag%20your,finish%20even%20simple%2C%20everyday%20tasks.`
  }, {
    id: 56, name: `Signs of Avolition`, tags: ["Signs of Avolition", "Avolition signs"], category: ` Avolition/ Apathy`, description: `If you have avolition, you might find that you don't wash or groom yourself like you should. You might not show up for meetings or events that you had planned to attend.
You also might not respond when friends call, text, or email. You won't put in any effort at work or school. You might not pay bills or take care of other everyday tasks.
It's possible you may not notice these symptoms are happening. A friend or family member might have to point them out to you.`, link: ` https://www.webmd.com/mental-health/what-is-avolition#:~:text=It's%20normal%20to%20drag%20your,finish%20even%20simple%2C%20everyday%20tasks.`
  }, {
    id: 57, name: `Diagnosis for Avolition`, tags: ["Diagnosis for Avolition", "Avolition", "Avolition diagnosis"], category: ` Avolition/ Apathy`, description: `A mental health professional such as a psychologist or psychiatrist can diagnose avolition. You'll see one of these doctors if you have symptoms of schizophrenia, severe depression, or other disorders that cause avolition.
If your doctor thinks schizophrenia is behind your avolition, they will try to rule out medical conditions, drugs, or injuries that cause the same symptoms as schizophrenia.
To get a schizophrenia diagnosis, you need to have two or more of these symptoms:
•	Hallucinations
•	Delusions
•	Disorganized speech, like jumping from one topic to another or using combinations of words that don't make sense
•	Disorganized behavior, such as acting in an unusual way or not moving (catatonic)
•	What doctors call "negative" symptoms, such as a flat tone to your voice, less enjoyment in life, or avolition
Your doctor may use a questionnaire to figure out your symptoms and see if you have avolition or similar problems, such as:
•	Alogia (lack of talking)
•	Asociality (not motivated to be social)
•	Anhedonia (inability to feel pleasure, which can lead to a lack of motivation)`, link: ` https://www.webmd.com/mental-health/what-is-avolition#:~:text=It's%20normal%20to%20drag%20your,finish%20even%20simple%2C%20everyday%20tasks.`
  }, {
    id: 58, name: `Treatment for Avolition`, tags: ["Avolition", "Avolition treatment"], category: ` Avolition/ Apathy`, description: `Antipsychotic drugs are the main treatment for schizophrenia, whether or not you have avolition with it. These drugs affect dopamine levels in the brain. They work very well for symptoms like hallucinations and delusions, but they don't help as much for avolition. Sometimes antipsychotic drugs can make avolition worse.
Newer, second-generation antipsychotic drugs may work better for avolition or similar symptoms than older ones:
•	Aripiprazole (Abilify)
•	Brexpiprazole (Rexulti)”
•	Clozapine (Clozaril)
•	Iloperidone (Fanapt)
•	Olanzapine (Zyprexa)
•	Quetiapine (Seroquel)
•	Risperidone (Risperdal)
•	Ziprasidone (Geodon)
Antidepressant drugs may also help some people. Sometimes medications to treat memory or thinking can also help.
Besides medicine, you might try cognitive behavioral therapy (CBT), art therapy, or social skills training.
It can take a few tries to find a treatment or combination of treatments that helps you. If you still have avolition after treatment, see your doctor again to try something new.`, link: ` https://www.webmd.com/mental-health/what-is-avolition#:~:text=It's%20normal%20to%20drag%20your,finish%20even%20simple%2C%20everyday%20tasks.`
  }, {
    id: 59, name: `What is Anhedonia?`, tags: ["Anhedonia?", "Asociality"], category: `Associality/ Anhedonia`, description: `Anhedonia is the inability to feel pleasure. It's a common symptom of depression as well as other mental health disorders.
Most people understand what pleasure feels like. They expect certain things in life to make them happy. Maybe you enjoy riding your bike, listening to the sounds of the ocean, or holding someone's hand. But some people lose the ability to feel joy. The things that once made them content are no longer fun or enjoyable. That's anhedonia.
There are two main types of anhedonia:
•	Social anhedonia. You don’t want to spend time with other people.
•	Physical anhedonia. You don’t enjoy physical sensations. A hug leaves you feeling empty rather than nurtured. Your favorite foods taste bland. Even sex can lose its appeal.
Anhedonia makes relationships, including those with friends and family members, a struggle. With the reward of enjoyment gone, it's hard to get motivated to spend time with others. You might turn down invitations and skip events like concerts, parties, and even one-on-one get-togethers because you no longer believe there's any benefit in taking part.
Or you could have social anxiety. You feel like you don't fit in, especially when meeting new people.
Relationships also thrive on positive feedback, and without it they can wither: Imagine not being able to tell someone you love them or that you had a great time spending the day with them. But if you have anhedonia, you can't, because you don't have those feelings. Meanwhile, loss of libido can take a toll on a romantic relationship.
It's also worth noting that some scientists believe anhedonia isn't always a black-and-white issue. You might feel no joy at all, or you could find that your positive emotions are dulled. In other words, it's possible to still like eating chocolate ice cream or listening to jazz; you just don't like those things nearly as much as you used to for reasons you can't explain.`, link: ` https://www.webmd.com/depression/what-is-anhedonia `
  }, {
    id: 60, name: `What Causes Anhedonia`, tags: ["Anhedonia", "Asociality"], category: `Asociality/ Anhedonia`, description: `Anhedonia is closely linked to depression, but you don't have to be depressed or feel sad to have it. It also affects people with other mental illnesses, like schizophrenia and bipolar disorder. And it can show up in people with seemingly unrelated health concerns like Parkinson's disease, diabetes, coronary artery disease, and substance abuse issues.
Scientists think anhedonia may be tied to changes in brain activity. You might have a problem with the way your brain produces or responds to dopamine, a "feel-good" mood chemical. Some early research (on rats) suggests that the dopamine neurons in an area of the brain called the prefrontal cortex might be overactive in people with anhedonia. This somehow interferes with pathways that control how we seek out rewards and experience them.`, link: ` https://www.webmd.com/depression/what-is-anhedonia `
  }, {
    id: 61, name: `How is Anhedonia Treated?`, tags: ["Anhedonia Treatment?", "Treatment for Anhedonia"], category: `Associality/ Anhedonia`, description: `Anhedonia can be tricky to treat. There’s no clear way to do it. The first step is generally to find any unknown cause, focus on treating that issue, and hope the anhedonia gets better as a result.
That's often true, especially when it comes to depression. People who take antidepressant medications (selective serotonin reuptake inhibitors, or SSRIs) may find that anhedonia improves along with the rest of their depression symptoms, but that's not always the case. Sometimes these medications blunt emotions and make this problem worse.
Scientists are working on new treatments for people with anhedonia who don't get better with treatments like SSRIs and talk therapy. One that shows promise is ketamine, a medication best known for being a party drug that also has an antidepressant effect. More research is needed, but at least one study found that people with bipolar depression who had anhedonia got relief from this symptom within 40 minutes of a ketamine shot.`, link: ` https://www.webmd.com/depression/what-is-anhedonia `
  }, {
    id: 62, name: `What is Reduced Attention?`, tags: ["Reduced Attention", "Attention deficit", "ADHD"], category: ` Reduced attention`, description: `It’s not unusual to find your mind wandering when you should be focused on something. According to a 2010 study, we spend nearly 47 percent of our waking hours thinking about something other than what we are doing.
It’s not always cause for concern, but a short attention span may sometimes be a sign of an underlying condition, such as attention deficit hyperactivity disorder (ADHD).`, link: `https://www.healthline.com/health/short-attention-span#causes`
  }, {
    id: 63, name: `Risk factors for having reduced attention`, tags: ["Reduced attention risk factors", "attention deficit"], category: ` Reduced attention`, description: `People with a short attention span may have trouble focusing on tasks for any length of time without being easily distracted.
A short attention span can have several negative effects, including:
•	poor performance at work or school
•	inability to complete daily tasks
•	missing important details or information
•	communication difficulties in relationships
•	poor health related to neglect and inability to practice healthy habits`, link: `https://www.healthline.com/health/short-attention-span#causes`
  }, {
    id: 64, name: `Causes of reduced attention`, tags: ["reduced attention causes", "ADHD", "Depression", "Head injury", "Learning disabilities"], category: ` Reduced attention`, description: `A short attention span can be caused by numerous psychological and physical conditions. The following are possible causes of a short attention span and other symptoms to be aware of.
ADHD
ADHD is a common disorder usually diagnosed in childhood that often lasts into adulthood. People with ADHD often have trouble paying attention and controlling their impulses.
Being overly active is a symptom of ADHD, but not everyone with the disorder has the hyperactivity component.
Children with ADHD may have poor grades. In some cases, they may spend too much time daydreaming. Adults with ADHD may often change employers and have repeated relationship problems.
Other signs and symptoms of ADHD include:
•	periods of hyperfocus
•	time management problems
•	restlessness and anxiety
•	disorganization
•	forgetfulness
Depression
Difficulty concentrating is a common symptom of depression. Depression is a mood disorder that can severely affect your life. It causes persistent feelings of sadness and loss of interest in things you once enjoyed.
Signs and symptoms of depression may include:
•	feelings of sadness and hopelessness
•	thoughts of suicide
•	tearfulness
•	loss of interest or pleasure
•	extreme fatigue
•	difficulty sleeping or sleeping too much
•	unexplained physical symptoms, such as body pain and headaches
Head injury
Attention problems are among the most commonly reported issues after sustaining a brain injury. A head injury is any type of injury to your head, scalp, skull, or brain.
It can be an open or closed injury and range from a mild bruise or bump to a traumatic brain injury (TBI). Concussions and skull fractures are common head injuries.
Symptoms of a head injury may include:
•	headache
•	dizziness
•	nausea
•	confusion
•	personality changes
•	vision disturbance
•	memory loss
•	seizures
Learning disabilities
Learning disabilities are neurodevelopmental disorders that interfere with basic learning skills, such as reading and calculating. There are many different types of learning disabilities. The most common ones are:
•	dyslexia
•	dyscalculia
•	dysgraphia
The most common signs and symptoms of a learning disability include:
•	difficulty following directions
•	poor memory
•	poor reading and writing skills
•	eye-hand coordination difficulties
•	being easily distracted`, link: `https://www.healthline.com/health/short-attention-span#causes`
  },]
