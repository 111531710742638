import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {questions,definitions} from "./questions";
import { Link } from "react-router-dom";

const App = () => {
  let [showResults, setShowResults] = useState(false);
  let [results, setResults] = useState("");
  let [emotions, setEmotions] = useState("");
  let distributions = {p1:6,p2:9,p3:7,p4:6,p5:5,n1:7,n2:6,n3:8,n4:8,n5:6}
  const { register, handleSubmit,errors, reset } = useForm();
 

 

  const renderQuestions = (data)=> {
     return data.map((obj, index)=> question(
      obj.qs,
      `q${index}_${obj.type}`,
      1,
      0,
      index
    ))

  }

  const question = (question, key, yes, no, index) => {
    return (
      <div key={key} className="field">
        <label className="label">{`${index+1}. ${question}`}</label>
        <div className="control">
          <label className="radio">
            <input type="radio" ref={register({required:false})} value={yes} name={key} />
            Yes
          </label>
          <label className="radio">
            <input type="radio" ref={register({required:false})} value={no} name={key} />
            No
          </label>
          
        </div>
        <label>{errors[key]&& <span className="has-text-danger">This field is required!</span>}</label>
      </div>
    );
  };

  const onSubmit = (data) => {
   
    let results = data;
    let score = 0;
    let emotions = {p1:0,p2:0,p3:0,p4:0,p5:0,n1:0,n2:0,n3:0,n4:0,n5:0};
  
    for (let [key, value] of Object.entries(results)) {
      let type = key.split("_")[1];

      if (value) {
        if(typeof emotions[type]==="number"){
          emotions[type] = emotions[type]+parseInt(value)
        }
        score = score + parseInt(value);
      }
    }

    score = ((score/68)*100).toFixed()
    setEmotions(emotions);
    setResults(score);
    setShowResults(true);
  };

  const displayResults = (results) => {
    return (
      <div className="section">
        <div className="container has-text-centered">
          <div className="columns has-text-centered">
            <div className="column">
              <div className="mt-5 pt-5">
                {" "}
                <p>
                 {" "}
                  <span className="title has-text-black has-text-weight-medium">
                  Likelihood of having Schizophrenia
                  </span>
                </p>

                <p>
                 {" "}
                  <span className="title has-text-dark has-text-weight-bold">
                 {`${results}%`}
                  </span>
                </p>

               
                <br/>

                <p>
                 {" "}
                  <span className="title has-text-black has-text-weight-medium">
                  Individual likelihood of schizo symptoms
                  </span>
                </p>


                
                <div>
                  <br />
                </div>
                <table className="table is-bordered">
                  <thead>
                    <tr>
                      <th>Symptom</th>
                      <th>Description</th>
                      <th>Likelihood</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Object.keys(emotions).map((emotion,index)=>{
                      let symptom_score = ((emotions[emotion]/distributions[emotion])*100);
                  return  <tr key={index}>
                      <td>{definitions[index].name}</td>
                      <td>{definitions[index].description}</td>
                      <td>{symptom_score.toFixed()}%</td>
                      <td>{symptom_score>40 ?<Link to={`/wiki/${definitions[index].id}`} target={`_blank`}>Attn: Click to know more!</Link>:"N/A"}</td>
                      </tr>})}
                   
                   </tbody>
                </table>
                <br />
                <button
                  className="button is-link"
                  onClick={() => setShowResults(false)}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (showResults) {
    return <div>{displayResults(results)}</div>;
  }

  return (
    <div className="section">
      <div className="container">
        {" "}
        <div className="columns">
          <div className="column is-2"></div>
          <div className="column is-8 has-text-centered">
            {" "}
            <div className="field has-text-centered">
              <p className="title">Self Reporting Questionnaire For Schizophrenia</p>
              <br />
            </div>
            <form
              className="notification has-background-white-bis has-text-left"
              onSubmit={handleSubmit(onSubmit)}
            >
             {renderQuestions(questions)}
              <div className="field has-text-centered">
                <br />{" "}
                <div className="level">
                  <div className="level-item">
                    <div className="buttons">
                      {" "}
                      <button className="button is-link" type="submit">
                        Submit
                      </button>
                      <input
                        className="button is-light"
                        type="button"
                        value="Clear"
                        onClick={() => reset()}
                      />
                    </div>
                  </div>
                 
                </div>
              </div>
            </form>
          </div>
          <div className="column is-2"></div>
        </div>
      </div>
    </div>
  );
};

export default App;
